import React from "react";
import SVGIcon from "./SVGIcon";

const LinkedInIcon = ({ color, ...props }) => (
  <SVGIcon {...props}>
    {/* <path
      fill={bg}
      fillRule="evenodd"
      d="M19 38c10.493 0 19-8.507 19-19S29.493 0 19 0 0 8.507 0 19s8.507 19 19 19z"
      clipRule="evenodd"
    ></path> */}
    <path
      fill={color}
      fillRule="evenodd"
      d="M15.2 25.967h-3.167V15.2H15.2v10.767zm-1.583-12.034c-.875 0-1.584-.72-1.584-1.59 0-.87.71-1.576 1.584-1.576a1.58 1.58 0 011.583 1.576c0 .87-.71 1.59-1.583 1.59zM27.23 25.967h-3.244v-5.113c0-1.219-.024-2.781-1.69-2.781-1.69 0-1.949 1.323-1.949 2.693v5.2H17.1V15.464h3.117v1.433h.046c.434-.825 1.494-1.696 3.076-1.696 3.289 0 3.894 2.174 3.894 4.997v5.77h-.003z"
      clipRule="evenodd"
    ></path>
  </SVGIcon>
);

LinkedInIcon.defaultProps = {
  color: "currentColor",
  // bg: "#DAE1E7",
  width: "38",
  height: "38",
  viewBox: "0 0 38 38",
};

export default LinkedInIcon;
