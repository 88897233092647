import React from "react";
import { graphql } from "gatsby";

import Image from "./image";
import MarkdownBlock from "./markdown-block";

export const BlogPostByLine = ({ name, date }) => {
  return (
    <p className="my-10 text-center text-gray-light">
      {name && `by ${name} • `}
      {date &&
        new Date(`${date}`).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
    </p>
  );
};

const BlogPostAuthor = ({ image, name, bio }) => {
  if (!name && !bio) {
    return null;
  }

  return (
    <section className="flex gap-5 border-t py-10 md:flex-row">
      {!!image && (
        <div className="h-52 w-52 flex-shrink-0 overflow-hidden rounded-full">
          <Image image={image} className="h-52 w-52" />
        </div>
      )}
      <div>
        <h2 className="mb-2 text-2xl font-bold">{name}</h2>
        <div className="text-lg text-gray-dark">
          <MarkdownBlock content={bio} />
        </div>
      </div>
    </section>
  );
};

export default BlogPostAuthor;

export const BlogPostAuthorFragment = graphql`
  fragment BlogPostAuthor on Frontmatter {
    author {
      name
      bio
      image {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: TRACED_SVG
            aspectRatio: 1
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;

export const blogPostAuthorField = {
  label: "Author",
  name: "author",
  widget: "object",
  fields: [
    {
      label: "Image",
      name: "image",
      widget: "image",
      required: false,
    },
    {
      label: "Name",
      name: "name",
      widget: "string",
      required: false,
    },
    {
      label: "Bio",
      name: "bio",
      widget: "markdown",
      required: false,
    },
  ],
};
