import React from "react";
import { graphql } from "gatsby";
import { useLocation } from "@reach/router";
import _get from "lodash.get";

import BlogPostAuthor, {
  BlogPostByLine,
  blogPostAuthorField,
} from "./blog-post-author";
import Layout from "../components/layout";
import SEOBlock, { seoFields } from "../components/seo";
import Image from "./image";
import MarkdownBlock from "../components/markdown-block";
import ShareBar from "../components/share-bar";

import usePreview from "../cms/usePreview";

import * as S from "./blog-post.styles";

const BlogPost = (data) => {
  const location = useLocation();
  const { isPreview } = usePreview();
  const pageUrl = _get(location, "href");
  const previewUrl = `${process.env.GATSBY_SITE_URL}/${_get(data, "slug")}`;
  const url = isPreview ? previewUrl : pageUrl;

  return (
    <Layout>
      <SEOBlock {..._get(data, "seo")} titleTemplate="%s | TED@Work" />
      <article css={[S.blogPostRoot, S.container]}>
        <header>
          <div css={[S.flexColRev, S.txtCenter, S.mb16, S.header]}>
            <h1 css={[S.text5xl, S.bold]}>{_get(data, "title")}</h1>
            <p css={[S.bold, S.uppercase, S.mb4]}>{_get(data, "tag")}</p>
          </div>
          <BlogPostByLine {..._get(data, "author")} date={_get(data, "date")} />
          <Image
            image={_get(data, "image")}
            imageUrl={_get(data, "imageUrl")}
            css={S.mb16}
          />
        </header>
        <div css={[S.pb28, S.blogText]}>
          <MarkdownBlock
            content={_get(data, "body")}
            images={_get(data, "images")}
          />
        </div>
        <footer>
          <BlogPostAuthor {..._get(data, "author")} />
          <ShareBar text={_get(data, "title")} url={url} />
        </footer>
      </article>
    </Layout>
  );
};

export default BlogPost;

export const BlogPostFragment = graphql`
  fragment BlogPost on MarkdownRemark {
    body: rawMarkdownBody
    frontmatter {
      seo {
        ...SEO
      }
      title
      tag
      date
      imageUrl
      image {
        childImageSharp {
          gatsbyImageData(
            width: 1200
            # placeholder: TRACED_SVG
            formats: [AUTO, WEBP]
          )
        }
      }
      ...BlogPostAuthor
    }
  }
`;

export const blogPostFields = [
  seoFields,
  { label: "Publish Date", name: "date", widget: "date" },
  {
    label: "Title",
    name: "title",
    widget: "string",
  },
  {
    label: "Slug",
    name: "slug",
    widget: "string",
  },
  // {
  //   label: "Published",
  //   name: "published",
  //   widget: "boolean",
  //   required: false,
  //   hint: "Unpublished pages will not appear on the front-end.",
  // },
  {
    label: "Tag",
    name: "tag",
    widget: "string",
    required: false,
  },
  {
    label: "Image",
    name: "image",
    widget: "image",
    required: false,
  },
  blogPostAuthorField,
  {
    label: "Preview",
    name: "previewText",
    widget: "markdown",
    required: false,
  },
  {
    label: "Body",
    name: "body",
    widget: "markdown",
    required: false,
  },
];
