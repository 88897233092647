import React from "react";
import { graphql } from "gatsby";
import _get from "lodash.get";

import BlogPost from "../components/blog-post";

const BlogPostTemplate = (props) => (
  <BlogPost
    body={_get(props, "data.markdownRemark.body")}
    images={_get(props, "data.allFile.images")}
    {..._get(props, "data.markdownRemark.frontmatter", {})}
  />
);

export default BlogPostTemplate;

export const BlogPostQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      ...BlogPost
    }
    allFile(filter: { relativeDirectory: { eq: "blog/media" } }) {
      images: nodes {
        relativePath
        childImageSharp {
          id
          gatsbyImageData(
            width: 1024
            # placeholder: TRACED_SVG
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  }
`;
