import React from "react";
import _get from "lodash.get";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import useSiteMetadata from "../hooks/use-site-meta-data";

import siteOGImage from "../images/TEDatWork.jpg";

const SEO = ({
  description,
  desc,
  lang,
  ogTitle,
  ogDesc,
  title,
  ogImage,
  ...props
}) => {
  const { pathname } = useLocation();
  const { siteUrl } = useSiteMetadata();
  const metaDesc = desc || description || ogDesc;

  const imagePath =
    _get(ogImage, "childImageSharp.gatsbyImageData.images.fallback.src") ||
    _get(ogImage, "publicURL") ||
    siteOGImage;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      {...props}
    >
      <title>{title}</title>
      <meta name="description" content={metaDesc} />

      <meta property="og:type" content="website" />
      <meta property="og:title" content={ogTitle || title} />
      <meta property="og:description" content={metaDesc} />
      <meta
        property="og:url"
        content={(pathname && `${siteUrl}${pathname}`) || siteUrl}
      />
      <meta property="og:image" content={`${siteUrl}${imagePath}`} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="1200" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={ogTitle || title} />
      <meta name="twitter:description" content={metaDesc} />
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: "TED@Work",
  ogTitle: "TED@Work : Inspire new ways of working",
  ogDesc:
    "TED Talks reimagined for workplace learning. Powerful talks with practical tools to activate ideas in your organization.",
};

export const seoFields = {
  label: "SEO",
  name: "seo",
  widget: "object",
  fields: [
    { label: "Title", name: "title", widget: "string", required: false },
    { label: "OG Title", name: "ogTitle", widget: "string", required: false },
    {
      label: "OG Image",
      name: "ogImage",
      widget: "image",
      hint: "Try to use an image that is 1200x630 pixels.",
      required: false,
    },
    { label: "Desc", name: "desc", widget: "string", required: false },
  ],
};

export const SEOFragment = graphql`
  fragment SEO on SeoData {
    ogTitle
    title
    ogImage {
      publicURL
      childImageSharp {
        gatsbyImageData(
          width: 1200
          height: 630
          formats: PNG
          placeholder: NONE
          breakpoints: 1200
        )
      }
    }
    desc
  }
`;

export default SEO;
