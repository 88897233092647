import { css } from "@emotion/react";

export {
  bold,
  container,
  flexColRev,
  mb4,
  mb8,
  mb16,
  mt8,
  pb28,
  pb72,
  textLg,
  text5xl,
  txtCenter,
  uppercase,
} from "../styles/shared";

export const blogPostRoot = css`
  --max-width: calc(1024px + var(--gutter) * 2);
`;

export const header = css`
  min-height: var(--hero-height);
`;

export const blogText = css`
  font-size: 18px;
  line-height: 1.76;
  letter-spacing: -0.02em;
`;
