import { css } from "@emotion/react";

export {
  baseList,
  bold,
  borderTop,
  flexCenterAll,
  flexColCenterAll,
  py12,
  mb4,
  mb6,
  textLg,
  uppercase,
} from "../styles/shared";

export const grid = css`
  display: grid;
  grid-template-columns: auto;
  grid-auto-flow: column;
  grid-row-gap: 0;
  grid-column-gap: 45px;
`;
