import React from "react";

import FacebookIcon from "./icons/FacebookIcon";
import LinkedInIcon from "./icons/LinkedInIcon";
import TwitterIcon from "./icons/TwitterIcon";

import * as S from "./share-bar.styles";

const encodeParams = (params) => new URLSearchParams(params).toString();

const platforms = [
  {
    name: "Facebook",
    Icon: FacebookIcon,
    popupSize: "height=285,width=550",
    shareLink: ({ url }) =>
      `https://facebook.com/sharer/sharer.php?${encodeParams({
        u: url,
      })}`,
  },
  {
    name: "LinkedIn",
    Icon: LinkedInIcon,
    popupSize: "height=500,width=500",
    shareLink: ({ url, text }) =>
      `https://www.linkedin.com/shareArticle?${encodeParams({
        mini: true,
        url,
        title: text,
      })}`,
  },
  {
    name: "Twitter",
    Icon: TwitterIcon,
    popupSize: "height=285,width=550",
    shareLink: ({ url, text }) =>
      `https://twitter.com/intent/tweet/?${encodeParams({
        url,
        text,
      })}`,
  },
];

export const ShareIcon = ({ Icon, popupSize, url, name, shareLink, text }) => {
  const shareUrl = shareLink({ url, text });
  const openPopup = (e) => {
    e.preventDefault();
    window.open(shareUrl, name, `${popupSize},resizable=1`);
  };

  return (
    <a
      href={shareUrl}
      target="_blank"
      rel="noreferrer noopener"
      aria-label={`Share on ${name}`}
      onClick={openPopup}
    >
      <Icon />
    </a>
  );
};

const ShareBar = (props) => (
  <section css={[S.flexColCenterAll, S.py12, S.borderTop, S.mb6]}>
    <h2 css={[S.bold, S.uppercase, S.textLg, S.mb4]}>Share</h2>
    <ul css={[S.baseList, S.grid]}>
      {platforms.map((platform) => (
        <li key={platform.name}>
          <ShareIcon {...platform} {...props} />
        </li>
      ))}
    </ul>
  </section>
);

export default ShareBar;
